import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { getQRCodeSelector } from "../../state/student/studentSelector";
import { getQRCode } from "../../state/student/studentSlice";
import QRCode from "react-qr-code";

const PayWithQR: React.FC = () => {
  const dispatch = useAppDispatch();
  const QRCodeState = useSelector(getQRCodeSelector);
  const qrCode = QRCodeState.data?.data.qr_code;

  const refreshQRCode = () => {
    dispatch(getQRCode());
  };

  const getQRCodeFromCookie = () => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === "qr_code") {
        return value;
      }
    }
    return null;
  };

  useEffect(() => {
    const checkAndRefreshQRCode = () => {
      const qrCodeFromCookie = getQRCodeFromCookie();

      if (!qrCodeFromCookie) {
        refreshQRCode();
      }
    };

    checkAndRefreshQRCode();

    const intervalId = setInterval(checkAndRefreshQRCode, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className="modal fade"
      id="payWithQR"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="form-content">
              <h2 className="xl-title text-center">Pay with QR Code</h2>

              <form className="max-width-350">
                <div className="qrCode">
                  <QRCode
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`${qrCode}`}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <label className=" w-100 text-center">
                  This QR Code will expire after a few minutes
                </label>
                <input
                  onClick={refreshQRCode}
                  type="button"
                  value="Refresh QR Code"
                  className="btn btn-primary w100"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayWithQR;
